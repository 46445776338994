import {ViewStream} from 'spyne';

export class PlaceholderView extends ViewStream {
    
    constructor(props={}) {
        props.id='placeholder';
        props.template = require('./templates/placeholder.tmpl.html');
        super(props);
    }
    
    addActionListeners() {
        // return nexted array(s)
        return [];
    }
    
    broadcastEvents() {
        // return nexted array(s)
        return [];
    }
   
    onRendered() {
    
    }
    
}

