require('./scss/main-placeholder.scss');



import {SpyneApp, Channel, ViewStream, ChannelFetch} from 'spyne';
import {PlaceholderView} from './js/placeholder-view';

import * as R from 'ramda';


const spyneConfig = {debug:true};

SpyneApp.init(spyneConfig);



new PlaceholderView().appendToDom(document.getElementById('app'));
